.signupPage {
    padding: 70px 0;
    background: url(../../components/img/loginBanner.png) center / cover no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .commityImg {
        max-width: 420px;
        margin: auto;
    }

    .signupForm {
        background: #081127;
        border-radius: 25px;
        padding: 60px 30px;

        h3 {
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            text-transform: capitalize;
        }

        .desc {
            color: #8BA4BF;
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 20px;
        }

        label {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #8BA4BF;
            margin-bottom: 5px;
        }

        .forget {
            font-weight: 600;
            font-size: 16px;
            text-align: right;
            text-transform: capitalize;
            background: linear-gradient(91.24deg, #4200FF 0.26%, #FF02C7 99.58%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            cursor: pointer;

            &:hover {
                background: linear-gradient(91.24deg, #4200FF 100%, #FF02C7 99.58%);
            }
        }

        .signupBtn {
            background: linear-gradient(91.24deg, #4200FF 0.26%, #FF02C7 99.58%);
            border-radius: 12px;
            text-align: center;
            color: white;
            text-transform: uppercase;
            padding: 15px; // Ajustez ce padding si nécessaire
            margin-top: 30px;
            cursor: pointer;
            transition: 0.3s;
            border: none;
            width: 100%;

            &:hover {
                background: linear-gradient(91.24deg, #4200FF 100.26%, #FF02C7 99.58%);
            }
        }

        .noAccount {
            font-family: 'BR Firma';
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            margin: 16px 0 4px 0;
        }

        .signup {
            color: #F302CA;
            text-align: center;
            cursor: pointer;
            width: max-content;
            margin: auto;

            &:hover {
                color: #4200FF;
            }
        }
    }

    .titleContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        h3 {
            margin: 0;
        }
    }

    .buttonBackContainer {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer; 
        padding: 0; 
        outline: none; 
        transition: transform 0.2s; 

        img {
            width: 30px; 
            height: 30px;
            margin-right: 5px; 
            filter: brightness(0) invert(0.5); 
        }

        p {
            margin: 0;
            filter: brightness(0) invert(0.5); 
        }
    }

    .error-group {
        .form-control {
            border: 1px solid  #FF6F61;
        }
    } 

    .errorMessage {
        font-size: 12px;
        color:  #FF6F61;
    }

    .input-group {
        align-items: stretch; 

        input {
            flex: 1; 
            background: #162136;
            border-radius: 12px;
            border: none;
            color: white;
            margin-bottom: 20px;
            margin-top: 5px;            
            padding: 10px; 
        }

        button {
            background-color: #162136;
            font-size: 16px;
            padding: 10px; 
            color: #8BA4BF;
            border-radius: 12px;
            border: none;
            border-left: 2px solid #8BA4BF;
            cursor: pointer;
            outline: none;
            margin-bottom: 20px;
            margin-top: 5px;    
            width: 20%;
        }
    }

    .form-control {
        width: 100%;
        padding: 10px;
        border-radius: 12px;
        border: none;
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 5px;
        background: #162136;
        color: #fff;

        &:focus, &:active {
            background: #162136;
            color: #fff;
        }
    }

    .languageSwitcherContainer {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 0;
    }
}
